import Featuress from './Featuress.css'



const Features = () => {
    return ( 
        <>
        <div className="container mt-5 mb-3">
            <div className="row">
        <div className="container col-lg-3 col-sm-6 col-xs-6 mt-2 ">
            <div className="card">
                <img src="../images/agriculture.webp" className="card-img-top img-fluid"alt=""/>
                <div className="card-body">
                    <h5 className="card-title text-black">Agriculture</h5>
                    <p className="card-text text-black"> The Indian agriculture industry is poised for huge growth and the sector is increasing its contribution to the global food trade every year due to its immense potential for value addition, particularly within the food processing industry. </p>
                </div>
            </div>   
        </div>

        <div className="container col-lg-3 col-sm-6 col-xs-6 mt-2">
            <div className="card">
                <img src="../images/artificial-intelligence-industry.jpg" className="card-img-top img-fluid" alt=""/>
                <div className="card-body">
                    <h5 className="card-title text-black">Artifitial Intelligence</h5>
                    <p className="card-text text-black">The artificial intelligence (AI) sector focuses on developing systems that simulate human intelligence, enabling machines to learn, reason, and make decisions. Skill development in AI includes learning programming, machine learning algorithms, data analysis, and deploying AI solutions across industries like healthcare, finance, and automation.</p>
                </div>
            </div>   
        </div>

        <div className="container col-lg-3 col-sm-6 col-xs-6 mt-2">
            <div className="card">
                <img src="../images/Industrial-Robotics.webp" className="card-img-top img-fluid" alt=""/>
                <div className="card-body">
                    <h5 className="card-title text-black">Robotics</h5>
                    <p className="card-text text-black">The robotics sector combines engineering, computer science, and AI to design and develop intelligent machines that perform tasks autonomously or semi-autonomously. Skill development in robotics involves programming, mechanical design, electronics, and integrating sensors and actuators to create functional robotic systems.</p>
                </div>
            </div>   
        </div>

        <div className="container col-lg-3 col-sm-6 col-xs-6 mt-2">
            <div className="card">
                <img src="../images/healthcare.avif" className="card-img-top img-fluid" alt=""/>
                <div className="card-body">
                    <h5 className="card-title text-black">Healthcare</h5>
                    <p className="card-text text-black"> Healthcare is one of IndiaÕs largest sectors, both in terms of revenue and employment broadly comprises hospitals, medical devices, clinical trials, outsourcing, telemedicine, medical tourism, health insurance and medical equipment.</p>
                </div>
            </div>   
        </div>
        </div>
        </div>
        </>
      
    )
}

export default Features;