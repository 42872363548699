import { useState } from "react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
//import BsNavbarr from "./BsNavbarr.css"


const BsNavbar = () => {
    const [show, setShow] = useState(false);
    const auth= localStorage.getItem('user');
    const navigate = useNavigate();
    const logout =()=>{
      localStorage.clear();
      navigate('/signup');
    }
    return (
        <>
        
        {/*<section className="navbar-bg">*/}
        <nav class="navbar navbar-expand-lg fixed-top bg-body-tertiary mb-5">
          <div class="container">
            {/*<a class="navbar-brand" href="#">Wellness Warriors</a>*/}
            <img src='/navdakshata_logo.jpeg' alt="Logo" style={{ height: "50px", width: "60px" }} />
            <Link to="/" className="navbar-brand">NAVDAKSHATA PLATFORM</Link>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" 
                aria-label="Toggle navigation" onClick={()=> setShow(!show)}>
                <span class="navbar-toggler-icon"></span>
              </button>
    <div class={`collapse navbar-collapse ${show ? "show" : ""}`} >
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        {
          // dynamic code for menu    
        }
        <li class="nav-item">
          <Link to="/aboutus" className="nav-link" aria-current="page">About Us</Link>
          {/*<a class="nav-link active" aria-current="page" href="#">ABOUT US</a>*/}
        </li>
        <li class="nav-item">
          <Link to="/services" className="nav-link">Sectors</Link>
          {/*<a class="nav-link" href="#">SERVICES</a>*/}
        </li>
        {/* <li class="nav-item">
          <Link to="/psy" className="nav-link">Psychologists</Link>
          <a class="nav-link" href="#">SERVICES</a> 
        </li>*/}
        {/* <li class="nav-item">
          <Link to="/delete" className="nav-link">Delete</Link>
          <a class="nav-link" href="#">EXPERTSS</a>
        </li> */}

        {/*<li className="nav-item">
          <Link to="/profile" className="nav-link">Profile</Link>
      </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Experts
          </a>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item" href="#">Therapists</a>
              </li>
            <li>
              <a class="dropdown-item" href="#">Psychiatrists</a>
              </li>
            <li>
              <a class="dropdown-item" href="#">Couple therapists</a>
              </li>
          </ul>
        </li>

        <li className="nav-item">{auth ?<Link to="/signup" onClick={logout} className="nav-link">Logout</Link>:
          <Link to="/signup" className="nav-link">Sign Up</Link>}
        </li>*/}
      </ul>
      <form class="d-flex" role="search">
        {/* <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/> 
        <button class="btn btn-outline-dark me-1" type="submit">Login{""}</button>
        <button class="btn btn-outline-dark" type="submit">Sign Up{""}</button>*/}
      </form>
    </div>
  </div>
</nav>
{/*</></section>*/}

        </>
    )
}

export default BsNavbar;