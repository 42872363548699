const AboutUs = () => {

    return(
        <>
        <div className="container">
            <div className="row">

                <div className="col-6 mt-5 bg-primary">

                </div>

                <div className="col-6 mt-5 p-1 bg-info">

                </div>

                <div className="col-lg-12 col-sm-12 mt-1 p-1 text-center">About Us
                <h3>Meet the Founders</h3><br/>
                <p>Nav Dakshata is co-founded by <b>Ved Prakash</b> & <b>Kundan Prakash</b> in September 2024, Welcome to Nav Dakshata Private Limited, 
                a forward-thinking organization dedicated to empowering the youth of India with transformative skill development initiatives. </p>
                    <p>At <b>Nav Dakshata</b>, we envision a future where every young individual has the tools, knowledge, and confidence to succeed in an ever-evolving world. 
                    Our mission is to bridge the gap between potential and opportunity by providing cutting-edge training programs, industry-relevant education, and hands-on experiences.</p>
                         <p>We specialize in nurturing talent across diverse sectors, ensuring that our youth are not only employable but also equipped to innovate and lead. 
                            Through collaborations with industry leaders, government bodies, and educational institutions, we aim to create a skilled workforce that drives India’s growth story.</p></div>

                
                <div className="col-12 p-5 "></div>
                

            </div>
            
        </div>
        </>
    )
}

export default AboutUs;