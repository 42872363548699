import React from "react";
import Headerss from "./Headerss.css"
import { Link } from "react-router-dom";
import wwApp from "../src/components/Wellness-Warriors.apk"

const Headers =()=> {
    return(
        <>
        <header>
            <section className="container col-12 mt-5">
                <div className="card">
                        <img src="../images/titleimage_.webp" className="card-img" alt="abc"/>
                            <div className="card-img-overlay h-100 d-flex flex-column justify-content-center">
                                {/* <h5 className="card-title">Wellness Warriors </h5> 
                                <p className="col-3 card-text">Wellness Warriors Welcomes You !</p>*/}
                                <p className="col-3 card-text">Skill India<i class="fa fa-inr"></i> Hub</p>
                                {/* <p className="card-text">Yeah! we did it nicely </p> 
                                <button class="col-3 btn btn-outline-dark" type="submit">Get Started{""}</button>
                                    <img src="../images/appStore.png" className="col-2" />*
                                    <a href={wwApp} download="Install-WellnessWarrior-apk"><img src="../images/ww_android_app.webp" width='200' alt="Download Now"></img></a>/}
                                    {/*<Link to={wwApp} download="ww-apk"><img src="../images/ww_android_app.webp" className="col-2"  /></Link>*/}
                            </div>
                </div>

                    {/* right side section  

                    <div className="">
                        <h1 className="display-6">Wellness Warriors </h1>
                        <p className="main-hero-para">We help you see new perspectives !! </p>
                        <p>Lets talk to qualified therapists and psychiatrists, <br></br>as well as community access to provide best mental healthcare services.</p>
                    </div>*/}

                    {/* <div className="col-12 col-lg-6 header-right-side d-flex justify-content-center align-items-center"> 
                         <img src="./images/girl-laughing.jpeg" alt="mental health" className="img-fluid" /> 

                    </div>*/}
                
            </section>
        </header>
        </>
    );
};

export default Headers;